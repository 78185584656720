<template>
  <v-layout row wrap max-width-1400 full-width ma-auto justify-space-between>
    <v-flex pb-3 xs12 sm2>
      <v-card
        color="car-blue"
        class="border-radius-5 clickable grow"
        :height="$vuetify.breakpoint.smAndUp ? '140px' : '80px'"
        :max-width="$vuetify.breakpoint.smAndUp ? '200px' : '100%'"
        @click="$goTo('/auto', 'goto_car')"
      >
        <v-card-text>
          <p class="font-16 graphik-bold white--text mb-0" :class="{'text-xs-center': $vuetify.breakpoint.smAndUp, 'mt-12px': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_my') }} <span class="lowercase">{{ $ml.get('product_car') }}</span></p>

          <v-img
            :aspect-ratio="1"
            height="100%"
            width="100%"
            contain
            position="right bottom"
            class="absolute"
            :src="oval"
            :lazy-src="oval"
          >
            <v-img
              :aspect-ratio="1"
              height="100%"
              width="100%"
              contain
              position="right bottom"
              class="absolute"
              :src="images.car"
              :lazy-src="images.car"
            ></v-img>
          </v-img>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex pb-3 xs12 sm2>
      <v-card
        color="house-yellow"
        class="border-radius-5 clickable grow"
        :height="$vuetify.breakpoint.smAndUp ? '140px' : '80px'"
        :max-width="$vuetify.breakpoint.smAndUp ? '200px' : '100%'"
        @click="$goTo('/hogar', 'goto_house')"
      >
        <v-card-text>
          <p class="font-16 graphik-bold white--text mb-0" :class="{'text-xs-center': $vuetify.breakpoint.smAndUp, 'mt-12px': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_my') }} <span class="lowercase">{{ $ml.get('product_house') }}</span></p>

          <v-img
            :aspect-ratio="1"
            height="100%"
            width="100%"
            contain
            position="right bottom"
            class="absolute"
            :src="oval"
            :lazy-src="oval"
          >
            <v-img
              :aspect-ratio="1"
              height="100%"
              width="100%"
              contain
              position="right bottom"
              class="absolute"
              :src="images.house"
              :lazy-src="images.house"
            ></v-img>
          </v-img>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex pb-3 xs12 sm2>
      <v-card
        color="fleet-pink"
        class="border-radius-5 clickable grow"
        :height="$vuetify.breakpoint.smAndUp ? '140px' : '80px'"
        :max-width="$vuetify.breakpoint.smAndUp ? '200px' : '100%'"
        @click="$goTo('/flotillas', 'goto_fleet')"
      >
        <v-card-text>
          <p class="font-16 graphik-bold white--text mb-0" :class="{'text-xs-center': $vuetify.breakpoint.smAndUp, 'mt-12px': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_my') }} <span class="lowercase">{{ $ml.get('product_fleet') }}</span></p>

          <v-img
            :aspect-ratio="1"
            height="100%"
            width="100%"
            contain
            position="right bottom"
            class="absolute"
            :src="oval"
            :lazy-src="oval"
          >
            <v-img
              :aspect-ratio="1"
              height="100%"
              width="100%"
              contain
              position="right bottom"
              class="absolute"
              :src="images.fleet"
              :lazy-src="images.fleet"
            ></v-img>
          </v-img>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex pb-3 xs12 sm2>
      <v-card
        color="uber-green"
        class="border-radius-5 clickable grow"
        :height="$vuetify.breakpoint.smAndUp ? '140px' : '80px'"
        :max-width="$vuetify.breakpoint.smAndUp ? '200px' : '100%'"
        @click="$goTo('/uber', 'goto_uber')"
      >
        <v-card-text>
          <p class="font-16 graphik-bold white--text mb-0" :class="{'text-xs-center': $vuetify.breakpoint.smAndUp, 'mt-12px': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_my') }} <span class="lowercase">{{ $ml.get('product_uber') }}</span></p>

          <v-img
            :aspect-ratio="1"
            height="100%"
            width="100%"
            contain
            position="right bottom"
            class="absolute"
            :src="oval"
            :lazy-src="oval"
          >
            <v-img
              :aspect-ratio="1"
              height="100%"
              width="100%"
              contain
              position="right bottom"
              class="absolute"
              :src="images.uber"
              :lazy-src="images.uber"
            ></v-img>
          </v-img>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex pb-3 xs12 sm2>
      <v-card
        color="life-cyan"
        class="border-radius-5 clickable grow"
        :height="$vuetify.breakpoint.smAndUp ? '140px' : '80px'"
        :max-width="$vuetify.breakpoint.smAndUp ? '200px' : '100%'"
        @click="$goTo('/vida', 'goto_life')"
      >
        <v-card-text>
          <p class="font-16 graphik-bold white--text mb-0" :class="{'text-xs-center': $vuetify.breakpoint.smAndUp, 'mt-12px': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_insurance') }} <span class="lowercase">{{ $ml.get('product_life') }}</span></p>

          <v-img
            :aspect-ratio="1"
            height="100%"
            width="100%"
            contain
            position="right bottom"
            class="absolute"
            :src="oval"
            :lazy-src="oval"
          >
            <v-img
              :aspect-ratio="1"
              height="100%"
              width="100%"
              contain
              position="right bottom"
              class="absolute"
              :src="images.life"
              :lazy-src="images.life"
            ></v-img>
          </v-img>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentProducts',
  data () {
    return {
      images: {
        car: require('@/assets/img/images/products_car.png'),
        house: require('@/assets/img/images/products_house.png'),
        fleet: require('@/assets/img/images/products_fleet.png'),
        uber: require('@/assets/img/images/products_uber.png'),
        life: require('@/assets/img/images/products_life.png')
      }
    }
  },
  computed: {
    oval () {
      return this.$vuetify.breakpoint.xsOnly ? require('@/assets/img/images/oval_mobile.png') : require('@/assets/img/images/oval.png')
    }
  }
}
</script>

<style scoped>
  .absolute {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  .car-blue {
    background-color: #6FA1F0 !important;
    box-shadow: 5px 5px 8px 0 rgba(130, 131, 131, 0.15);
  }

  .house-yellow {
    background-color: #F2BE2C !important;
    box-shadow: 5px 5px 8px 0 rgba(130, 131, 131, 0.15);
  }

  .fleet-pink {
    background-color: #F37777 !important;
    box-shadow: 5px 5px 8px 0 rgba(130, 131, 131, 0.15);
  }

  .uber-green {
    background-color: #3FD4C2 !important;
    box-shadow: 5px 5px 8px 0 rgba(130, 131, 131, 0.15);
  }

  .life-cyan {
    background-color: #63D2FF !important;
    box-shadow: 5px 5px 8px 0 rgba(130, 131, 131, 0.15);
  }

  .grow {
    transition: all .2s ease-in-out;
  }

  .grow:hover {
    transform: scale(1.1);
  }

  .mt-12px {
    margin-top: 12px !important;
  }
</style>